<template>
  <div>
    <b-card-group>
      <Membership/>
    </b-card-group>
  </div>
</template>

<script>
  import Membership from '@/components/Membership.vue'

  export default {
    components: { Membership }
  }
</script>
