<template>
<div class="sm-8 mb-3">
  <div>

    <h2>Välkommen!</h2>
    <p>
      Har du varit medlem under 2021 eller senare bör du redan se dina uppgifter här nedanför.<br/>
      Om du inte ser dina uppgifter, logga ut och logga in med samma epostadress du använde vid förra terminens registrering.<br/>
      Kontakta <a href="mailto:info@ujjk.se">info@ujjk.se</a> om du har frågor.
    </p>
  </div>

  <!-- vuxna -->
  <div v-if="adultMembers.length">
    <b-card-group deck>
      <MemberInfo v-for="member in adultMembers" :key="member.id" :member="member" />
    </b-card-group>
  </div>

  <!-- barn -->
  <div v-if="childMembers.length">
    <h3>{{ childMembers.length === 1 ? 'Ditt' : 'Dina' }} barn</h3>
    <b-card-group deck>
      <MemberInfo v-for="member in childMembers" :key="member.id" :member="member" />
    </b-card-group>
  </div>

  <div class="mt-3">
    <h3 v-if="allMembers.length > 0">Anmäl fler personer</h3>
    <b-card bg-variant="light">
      <RegisterProspectForm
        :allowAdult="adultMembers.length === 0"
        :needGuardianInfo="adultMembers.length === 0" />
    </b-card>
  </div>
</div>
</template>

<script>
  import { mapState } from 'vuex';
  import { db } from '@/firebaseConfig.js'
  import { collection, onSnapshot, query, where } from 'firebase/firestore'
  import MemberInfo from '@/components/MemberInfo.vue'
  import RegisterProspectForm from '@/components/RegisterProspectForm.vue'


  export default {
    mounted() {
      const uid = this.$route.query.uid || this.user.id

      console.log(`binding members and prospects for ${uid}`)
      const memberQuery = query(collection(db, 'members'), where('userId', '==', uid))
      this.unsubMembers = onSnapshot(memberQuery, (querySnapshot) => {
        const result = []
        querySnapshot.forEach((doc) => {
          result.push({ id: doc.id, isMember: true, ...doc.data() })
        })
        this.members = result
      })

      const prospectQuery = query(collection(db, 'prospects'), where('userId', '==', uid))
      this.unsubProspects = onSnapshot(prospectQuery, (querySnapshot) => {
        const result = []
        querySnapshot.forEach((doc) => {
          result.push({ id: doc.id, ...doc.data() })
        })
        this.prospects = result
      })
    },
    destroyed() {
      this.unsubMembers()
      this.unsubProspects()
    },
    components: { MemberInfo, RegisterProspectForm },
    data() {
      return {
        members: [],
        prospects: [],
        unsubMembers: null,
        unsubProspets: null,
        signingUp: false
      }
    },
    computed: {
      ...mapState('user', ['user']),
      prospectsThatAreMembers() {
        return this.members.map(m => m.prospectId)
      },
      prospectsThatAreNotMembers() {
        return this.prospects.filter(p => !this.prospectsThatAreMembers.includes(p.id))
      },
      allMembers() { return [ ...this.members, ...this.prospectsThatAreNotMembers ] },
      adultMembers() { return this.allMembers.filter(m => !m.managedByGuardian) },
      childMembers() { return this.allMembers.filter(m => m.managedByGuardian) }
    }
  }
</script>
